<template>
  <div>
    <div class="mb-4">
      <transition name="fade">
        <error-message v-if="failed !== false" :message="$t(error)" />
      </transition>
    </div>

    <form action="#" method="POST">
      <div>
        <label for="username" class="block text-sm font-medium leading-5 text-gray-700">{{
          $t("login.username")
        }}</label>
        <div class="mt-1 rounded-md shadow-sm">
          <input
            v-model="username"
            id="username"
            type="text"
            autocomplete="username"
            required
            class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div class="mt-6">
        <label for="password" class="block text-sm font-medium leading-5 text-gray-700">{{
          $t("login.password")
        }}</label>
        <div class="mt-1 rounded-md shadow-sm">
          <input
            v-model="password"
            id="password"
            type="password"
            autocomplete="current-password"
            required
            class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div class="mt-6">
        <span class="block w-full rounded-md shadow-sm">
          <button
            @click.prevent="login"
            id="username-login"
            type="submit"
            class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring ring-indigo-200 active:bg-indigo-700 transition duration-150 ease-in-out"
            :disabled="loading"
          >
            <span>{{ $t("login.login") }}</span>
          </button>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Username",
  data() {
    return {
      failed: false,
    };
  },
  computed: {
    ...mapState("auth", {
      error: state => state.errors?.username,
    }),
    ...mapState({
      loading: state => state.loading,
    }),
    ...mapFields("auth", ["username", "password"]),
  },
  created() {
    delete this.$http.defaults.headers.common["Authorization"];
    if (process.env.NODE_ENV === "development") {
      this.username = process.env.VUE_APP_COMPOS_USERNAME;
      this.password = process.env.VUE_APP_COMPOS_PASSWORD;
    }
  },
  methods: {
    login() {
      this.failed = false;
      this.$store
        .dispatch("auth/loginCheck")
        .then(() => {
          this.failed = false;
          this.$router.push({ name: "login-otp-select" });
        })
        .catch(error => {
          if ([400, 401].includes(error.response.status)) {
            //username or password mismatch/error
            this.failed = true;
          }
        });
    },
  },
};
</script>

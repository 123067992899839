<template>
  <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
    <div class="mb-4">
      <error-message v-if="error" :message="error" />
    </div>

    <form action="#">
      <div>
        <label for="username" class="block text-sm font-medium leading-5 text-gray-700">{{
          $t("login.otpmethod")
        }}</label>
        <div class="mt-1 rounded-md">
          <div v-for="(method, key) in otpMethods" :key="key">
            <label :for="method">
              <input
                v-focus
                :id="method"
                type="radio"
                :value="method"
                v-model="otpMethod"
                class="focus:outline-none focus:ring"
              />
              {{ $t(`login.otpsendmedium.${method}`) }}
            </label>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <span class="flex w-full rounded-md shadow-sm">
          <button
            @click.prevent="$router.push({ name: 'login' })"
            type="button"
            class="w-full mr-1 flex justify-center py-2 px-4 border border-gray-200 text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-100 focus:outline-none focus:ring active:bg-gray-100 transition duration-150 ease-in-out"
          >
            {{ $t("login.cancel") }}
          </button>

          <button
            @click.prevent="sendOtp"
            :class="{
              'bg-indigo-600': otpMethod,
            }"
            type="submit"
            class="w-full ml-1 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-indigo-200 text-white hover:bg-indigo-500 focus:outline-none focus:ring active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            {{ $t("login.otpmethodsend") }}
          </button>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "OtpSelect",
  computed: {
    ...mapFields("auth", ["otpMethod"]),
    ...mapState("auth", {
      error: state => state.errors.otpsend,
      otpMethods: state => state.otpMethods,
    }),
  },
  methods: {
    sendOtp() {
      this.$store
        .dispatch("auth/sendOtp")
        .then(() => {
          this.$router.push({ name: "login-otp-verify" });
        })
        .catch(() => {});
    },
  },
};
</script>

<template>
  <div class="w-full h-screen"></div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SithsVerified",
  beforeMount() {
    this.$store
      .dispatch("auth/verifySiths")
      .then(() => {
        this.$router.push({ name: this.currentUnitId ? "user-patients" : "login-select-unit" }).catch(e => {
          console.log(e);
        });
      })
      .catch(() => {
        this.$router.replace({ name: "login" }).catch(() => {});
      });
  },
  computed: {
    ...mapState("userExtra", {
      currentUnitId: state => state.currentUnitId,
    }),
  },
};
</script>

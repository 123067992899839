<template>
  <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
    <form action="#">
      <div>
        <div class="mt-1 rounded-md">
          <div for="username" class="block text-sm font-medium leading-5 text-gray-700">
            {{ $t("login.selectunit") }}
          </div>
          <div class="mt-1 rounded-md">
            <div v-for="(unit, key) in unitList" :key="key">
              <label :for="unit.id">
                <input
                  :id="unit.id"
                  type="radio"
                  :value="unit.id"
                  v-model="currentUnit"
                  v-focus
                  class="focus:outline-none focus:ring-2 ring-indigo-400"
                />
                {{ unit.name }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <span class="flex w-full">
          <button
            @click.prevent="cancel"
            type="button"
            class="w-full mr-1 flex justify-center py-2 px-4 border border-gray-200 text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-100 focus:outline-none focus:ring-gray-200 active:bg-gray-100 transition duration-150 ease-in-out"
          >
            {{ $t("login.cancel") }}
          </button>

          <button
            @click.prevent="setUnit"
            :disabled="!currentUnit"
            :class="{
              'bg-indigo-600': currentUnit,
            }"
            type="submit"
            class="w-full ml-1 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-200 hover:bg-indigo-500 focus:outline-none focus:ring ring-indigo-400 active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            {{ $t("button.continue") }}
          </button>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";

export default {
  name: "SelectUnit",
  computed: {
    ...mapFields("auth", ["currentUnit"]),
    ...mapState("auth", {
      error: state => state.errors?.unit,
    }),
    ...mapState("userExtra", {
      currentUnitName: state => state.currentUnitName,
      unitList: state => state.unitList,
    }),
    isSwitchUnit() {
      const params = this.$route?.params;

      return params ? params.switch : false;
    },
  },
  methods: {
    setUnit() {
      this.$store.dispatch("auth/setCurrentUnit", this.currentUnit).then(() => {
        this.$router.push({ name: "user-start" });
      });
    },
    cancel() {
      if (this.isSwitchUnit) {
        return this.$router.back();
      }

      this.$router.push({ name: "login" });
    },
  },
};
</script>

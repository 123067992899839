<template>
  <div>
    <div class="min-h-screen bg-gray-50 flex flex-col py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-12 w-auto" src="@/assets/images/compos-logo.svg" alt="Compos logo" />
        <h2 class="mt-4 text-center text-2xl leading-9 font-extrabold text-gray-900">{{ $t("login.login") }}</h2>
      </div>

      <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="mb-4">
          <div class="mb-4">
            <router-view :key="$route.fullPath" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
};
</script>

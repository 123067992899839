<template>
  <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
    <form action="#">
      <div class="mb-4">
        <error-message v-if="error" :message="$t(error)" />
      </div>

      <div>
        <div class="mt-1 rounded-md shadow-sm">
          <info-message v-if="!error" :message="$t('login.otpmessage')" />

          <label for="otp" class="block text-sm font-medium leading-5 text-gray-700">{{ $t("login.otpenter") }}</label>
          <div class="mt-1 rounded-md shadow-sm">
            <input
              v-focus
              v-model="otp"
              id="otp"
              type="text"
              autocomplete="off"
              required
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
      </div>

      <div class="mt-6">
        <span class="flex w-full rounded-md shadow-sm">
          <button
            @click.prevent="$router.back()"
            type="button"
            class="w-full mr-1 flex justify-center py-2 px-4 border border-gray-200 text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-100 focus:outline-none focus:ring active:bg-gray-100 transition duration-150 ease-in-out"
          >
            {{ $t("login.cancel") }}
          </button>

          <button
            @click.prevent="verifyOtp"
            type="submit"
            class="w-full ml-1 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            {{ $t("login.otpverify") }}
          </button>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "OtpVerify",
  props: ["otpVerificationError"],
  computed: {
    ...mapState("auth", {
      error: state => state.errors?.otpverify,
    }),
    ...mapFields("auth", ["otp"]),
    ...mapState("userExtra", {
      currentUnitId: state => state.currentUnitId,
      unitList: state => state.unitList,
    }),
  },
  methods: {
    verifyOtp() {
      this.$store
        .dispatch("auth/verifyOtp")
        .then(() => {
          this.$router.push({ name: this.currentUnitId ? "user-patients" : "login-select-unit" });
        })
        .catch(error => {
          console.log(error);
          return false;
        });
    },
  },
};
</script>

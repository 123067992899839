<template>
  <div id="siths-login-button">
    <span class="w-full inline-flex rounded-md shadow-sm">
      <button
        @click="$emit('sithslogin')"
        type="button"
        class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-300 active:bg-green-700 transition duration-150 ease-in-out"
      >
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          viewBox="0 0 24 24"
          class="w-8 h-8 mt-1"
          :disabled="loading"
          :class="{ 'animate-pulse': loading }"
        >
          <path d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
        </svg>
        <span class="text-2xl mx-1">{{ $t("login.siths") }}</span>
      </button>
    </span>
    <div class="mt-2">
      <transition name="fade">
        <error-message v-if="error" :message="$t(error)" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Siths",
  created() {
    delete this.$http.defaults.headers.common["Authorization"];
  },
  computed: {
    ...mapState("auth", {
      error: state => state.errors?.siths,
    }),
    ...mapState({
      loading: state => state.loading,
    }),
  },
};
</script>

<template>
  <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="mb-4">
      <div class="mb-4">
        <siths v-focus @sithslogin="sithslogin" />
      </div>
      <div class="relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center text-sm leading-5">
          <span class="px-2 bg-white text-gray-500">{{ $t("login.alternatemethod") }}</span>
        </div>
      </div>
    </div>

    <div class="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-10">
      <div class="mb-4">
        <username-password />
      </div>
    </div>
  </div>
</template>

<script>
import Siths from "@/components/login/Siths";
import UsernamePassword from "@/components/login/UsernamePassword";
import { mapState } from "vuex";

export default {
  name: "LoginStart",
  components: {
    Siths,
    UsernamePassword,
  },
  computed: {
    ...mapState("auth", {
      hsaid: state => state.hsaid,
    }),
  },
  methods: {
    sithslogin() {
      this.$store
        .dispatch("auth/loginSiths")
        .then(redirectUrl => {
          window.location = redirectUrl;
        })
        .catch(error => {
          console.log(error.response.data);
        });
    },
  },
};
</script>
